import NotFoundView from "../notfound/NotFoundView";
import Document from "./Document";
import {useEffect, useState} from "react";
import fetchWithRetry from "../../util/request";
import Header from "../../components/header/Header";

export const DOCUMENTS: {
    [key: string]: string
} = {
    gdpr: "/documents/gdpr.md"
}

export default function DocumentView(props: {
    documentName: string
}) {
    const document = DOCUMENTS[props.documentName];

    const [documentText, setDocumentText] = useState<string | null>(null);

    useEffect(() => {
        if (!document) return;

        return fetchWithRetry(res => {
            if (!res.ok) return false;
            res.text().then(text => setDocumentText(text));
            return true;
        }, document)
    })

    if (!document)
        return <NotFoundView/>;

    return <div className={"document-view"}>
        <Header/>
        {documentText === null ? <div className={"loader big center margin"}/> : <Document>{documentText}</Document>}
    </div>
}