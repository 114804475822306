import Header from "../../../components/header/Header";
import {createRef} from "react";

export default function CreateRatingView() {
    const passwordInput = createRef<HTMLInputElement>();
    const nameInput = createRef<HTMLInputElement>();
    const dateInput = createRef<HTMLInputElement>();

    return <div className={"admin-view"}>
        <Header/>
        <h2 style={{
            textAlign: "center"
        }}>Vytvoření odkazu pro hodnocení</h2>
        <div style={{
            margin: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
            gap: "10px"
        }}>
            <div>
                <label style={{
                    marginRight: "5px"
                }}>Jméno</label>
                <input type={"text"} ref={nameInput}/>
            </div>
            <div>
                <label style={{
                    marginRight: "5px"
                }}>Datum</label>
                <input type="date" ref={dateInput}/>
            </div>
            <div>
                <label style={{
                    marginRight: "5px"
                }}>Heslo</label>
                <input type={"password"} ref={passwordInput}/>
            </div>
            <button style={{
                width: "min-content"
            }} onClick={() => {
                fetch("/api/rating/admin", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        password: passwordInput.current?.value,
                        name: nameInput.current?.value,
                        date: dateInput.current?.valueAsNumber
                    })
                }).then(res => res.json())
                    .then(body => {
                        if (body.id) {
                            alert("https://www.jushi.cz/hodnoceni/" + body.id)
                        } else alert("Nastala chyba");
                    })
            }}>Vytvořit
            </button>
        </div>
    </div>
}