import "./NotFoundView.css"
import Header from "../../components/header/Header";

export default function NotFoundView() {
    return <div className={"not-found-view"}>
        <Header/>
        <div className={"info-message"}>
            <h2>Požadovaná stránka neexistuje</h2>
        </div>
    </div>
}