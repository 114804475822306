import "./WomenCircleSection.css"
import photo from "../../../asset/photos/womencircle.webp";

const WomenCircle = require("../../../asset/icon/4/ico.svg").ReactComponent;
const WomenCircleBg = require("../../../asset/icon/4/bg.svg").ReactComponent;

export default function WomenCircleSection() {
    return <div className={"section type womencircle"}>
        <div className={"text"}>
            <div className={"content"}>
                <WomenCircleBg className={"title-icon"} width={"80"}/>
                <h2>Ženské Kruhy</h2>
                <p>Při vedení ženských kruhů ráda improvizuji a učím ženy odpočívat a uvědomovat si sama sebe. </p>
            </div>
            {/*<div className={"button-container"}>*/}
            {/*    <Button href={"/akce"}>Vybrat akci</Button>*/}
            {/*</div>*/}
        </div>
        <div className={"photo-container"}>
            <img className={"photo"} src={photo} alt={""}/>
            <WomenCircle className={"background-icon"}/>
        </div>
    </div>
}