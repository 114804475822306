import "./Button.css"
import React from "react";
import {Link} from "react-router-dom";

export default function Button(props: {
    children: string,
    href?: string,
    onClick?: () => void
}) {
    const {children, href, onClick} = props;

    if (href) {
        return <Link to={href}>
            <div className={"button"}>
                {children}
            </div>
        </Link>
    }

    return <div className={"button"} onClick={() => {
        if (onClick) onClick();
    }}>
        {children}
    </div>
}