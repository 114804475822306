import "./StoryView.css";
import Header from "../../components/header/Header";

export default function StoryView() {
    return <div className={"story-view"}>
        <Header/>
        <h2>Můj příběh</h2>
        <p>Když jsem přišla na tento svět, byla jsem spokojené, veselé děvčátko, než se můj svět začal měnit v bolest,
            utrpení, ponižování, manipulaci, nepřijetí, psychické i fyzické týrání. Nikdo mě doma nechránil. Nejprve
            jsem si namlouvala, že to není pravda, že se to změní a budu zase přijímaná a milovaná bezpodmínečnou láskou
            jako dříve. Bohužel tomu tak nebylo. Přemýšlela jsem i nad tím, jak odejít z tohoto světa. Dnes jsem vděčná
            a pyšná, že jsem to neudělala. Trvalo to několik dlouhých let, než jsem se v 18 letech rozhodla odejít od
            rodičů a najít lepší život, kde mě budou milovat a přijímat. Cítila jsem nenávist, beznaděj, litovala jsem
            se, plakala a stěžovala si.</p>
        <p>Ta cesta nebyla jednoduchá. Když jsem naposledy pracovala jako zaměstnanec. Můj šéf se ke mně nechoval férově
            jako k ostatním, za což mu „děkuji“. Bez toho bych nedošla k rozhodnutí začít podnikat a přestat
            kouřit. </p>
        <p>Na své cestě jsem potkávala mnoho lidí, kteří mi pomáhali, když jsem myslela, že to nezvládnu. Nevím, jak by
            to jinak dopadlo. Také v mém životě byli lidé, kteří mě využívali, lhali mi, šikanovali mě, manipulovali se
            mnou, připravili mně o to, co jsem si vybudovala atd. Já jsem jim to dovolila, jinak jsem to neuměla. Když
            jsem si myslela, že mi v životě všechno vychází jak doma, tak v práci, přišla mi do života velká rána a
            zklamání. Musela jsem opustit své místo v podnikání. Měla jsem pocit, že mi někdo vrazil kudlu do srdce a
            zakopal sto sáhů pod zem. Chtěla jsem zemřít. Štěstí bylo a je, že mám syna a manžela. To byla jediná věc,
            která mě donutila najít zbytek svých sil a začít znovu a jinak. Došla jsem k uvědomění, že to takto dál
            nechci snášet. Začala jsem sama sobě naslouchat, chránit se, dávat si co mi rodiče nemohli dát, a hlavně být
            k sobě upřímná. V nejtěžší chvíli poznáte přátele. Jsem vděčná za všechny, kteří mi přišli do života. </p>
        <p>Děkuji lidem, kteří mi zdánlivě ubližovali a působili vnitřní bolest. Díky té vnitřní bolesti jsem si
            uvědomila, že to může být i jinak, než si myslím. Vydala jsem se proto na cestu vnitřního hledání a poznání.
            Začala jsem všem odpouštět. Žádala jsem o odpuštění ty, kterým jsem ublížila. Odpustila jsem i sobě a na
            život se dívám z jiného úhlu pohledu. Dnes vím, že to byla moje životní cesta. Vnitřní bolest, napětí a
            úzkost zmizely. Zažívám vnitřní klid, naplnění a když cítím vnitřní napětí, dokážu to zpracovat, abych opět
            byla ve svém klidu. Nikdy bych nevěděla, že se dá žít i jinak, kdybych se nedala na cestu seberozvoje, který
            trvá již 30 let.</p>
        <p>Při té nelehké cestě si uvědomíte, jaké dary díky tomu dostanete. Jako je cílevědomost, houževnatost,
            trpělivost, vděčnost, laskavost. Chápu ostatní, když jsou v nelehké životní situaci, dokážu vyřešit nelehké
            životní situace, mám nadhled nad životem a mnoho dalších vlastností. Uvědomíte si, že všichni to dělali
            nejlépe jak uměli a možná ani nevěděli, že jsem to tak prožívala. I já v životě jsem dělala chyby, špatná
            rozhodnutí, možná jsem někomu ublížila ani jsem o tom nevěděla a někdy jsem ubližovala vědomě, protože jsem
            měla v sobě bolest a jinak jsem to neuměla.</p>
        <p>Musíme najít sami sebe a určit si hranice, co si mohou ostatní k vám dovolit a co už ne.</p>
        <p>Jsem stále na své životní cestě, která mě naplňuje. Mám domov, rodinu, kterou nadevše miluji, jsem milovaná
            přijímaná a vděčná za svůj život.</p>
        <p>Pokud nejste spokojeni se svým životem, je to jen na vás, jak se rozhodnete. Máte možnost stěžovat si a
            zůstat tam kde jste, anebo se rozhodnout to udělat jinak. Doporučuji se vydat cestou poznání sama sebe. </p>
        <p>Jestliže se rozhodnete jít cestou poznání a cítíte, že bych Vám mohla být ku prospěchu, ozvěte se mi. </p>
        <p>S úctou a láskou</p>
        <p>Jarka</p>
    </div>
}