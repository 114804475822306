import "./ReferencesSection.css"
import referenceBackground from "../../../asset/photos/reference-background.webp";
import ReferenceEntry from "./entry/ReferenceEntry";
import {useEffect, useState} from "react";
import fetchWithRetry from "../../../util/request";

interface Reference {
    name: string
    stars: number
    content: string
    date: number
}

export default function ReferencesSection() {
    const [state, setState] = useState<Reference[] | null>();

    const
        [mouseDown, setMouseDown] = useState<{
            clientX: number,
            scrollLeft: number
        } | null>()

    useEffect(() => {
        return fetchWithRetry(async res => {
            if (!res.ok) return false;
            let body = (await res.json()) as Reference[];
            setState(body)
            return true;
        }, "/api/rating");
    }, [])

    if (!state) return null;

    return <div className={`refrences${mouseDown ? " grabbing" : ""}`}>
        <h3>Reference</h3>
        <div className={"content"}>
            <img src={referenceBackground} className={"background"} alt={""}/>
            <div className={"ratings"}
                 onMouseDown={(e) => setMouseDown({
                     clientX: e.clientX,
                     scrollLeft: (e.target as HTMLElement).scrollLeft
                 })}
                 onMouseUp={() => setMouseDown(null)}
                 onMouseMove={(e) => {
                     if (!mouseDown) return;
                     const element = e.target as HTMLElement;
                     element.scroll({
                         left: mouseDown.scrollLeft + (mouseDown.clientX - e.clientX)
                     })
                 }}>
                {
                    state.map((value, index) => {
                        return <ReferenceEntry
                            key={index} name={value.name}
                            stars={value.stars}
                            text={value.content}
                            date={value.date}/>
                    })
                }
            </div>
        </div>
    </div>
}