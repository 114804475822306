import "./ConsultationsSection.css"
import photoDark from "../../../asset/photos/consultations-dark.webp";
import photoLight from "../../../asset/photos/consultations-light.webp";

const ConsultationsBg = require("../../../asset/icon/2/bg.svg").ReactComponent;

export default function ConsultationsSection() {
    return <div className={"section type consultations"}>
        <div className={"text"}>
            <div className={"content"}>
                <ConsultationsBg className={"title-icon"} width={"80"}/>
                <h2>Konzultace</h2>
                <p>Naslouchám a řeším s klientem složité životní situace.</p>
            </div>
            {/*<div className={"button-container"}>*/}
            {/*    <Button href={"/konzultace"}>Domluvit schůzku</Button>*/}
            {/*</div>*/}
        </div>
        <div className={"photo-container"}>
            <img className={"photo only-light"} src={photoLight} alt={""}/>
            <img className={"photo only-dark"} src={photoDark} alt={""}/>
        </div>
    </div>
}