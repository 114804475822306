import "./SummarySection.css"

const LifeCoach = require("../../../asset/icon/1/ico.svg").ReactComponent;
const Consultations = require("../../../asset/icon/2/ico.svg").ReactComponent;
const Relax = require("../../../asset/icon/3/ico.svg").ReactComponent;
const WomenCircle = require("../../../asset/icon/4/ico.svg").ReactComponent;


export default function SummarySection() {
    return <div className={"section summary"}>
        <div className={"items"}>
            <div className={"item lifecoach"}>
                <LifeCoach/>
                <p className={"label"}>Životní Kouč</p>
            </div>
            <div className={"item consultations"}>
                <Consultations/>
                <p className={"label"}>Konzultace</p>
            </div>
            <div className={"item relax"}>
                <Relax/>
                <p className={"label"}>Relax</p>
            </div>
            <div className={"item womencircle"}>
                <WomenCircle/>
                <p className={"label"}>Ženské Kruhy</p>
            </div>
        </div>
        <h3 className={"summary-title"}>Pojďme společně najít cestu za lepším životem</h3>
    </div>
}