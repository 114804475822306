import "./App.css"

import React, {useLayoutEffect} from "react";
import WelcomeView from "./view/welcome/WelcomeView";
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import NotFoundView from "./view/notfound/NotFoundView";
import StoryView from "./view/story/StoryView";
import CertificatesView from "./view/certificates/CerificatesView";
import ContactView from "./view/contact/ContactView";
import RateView from "./view/rate/RateView";
import DocumentView, {DOCUMENTS} from "./view/document/DocumentView";
import CreateRatingView from "./view/admin/rating/CreateRatingView";

const ScrollWrapper = ({children}: { children: any }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

export default function App() {
    return <div className={"app"}>
        <BrowserRouter>
            <ScrollWrapper>
                <Routes>
                    <Route path="/" element={<WelcomeView/>}/>
                    <Route path="/pribeh" element={<StoryView/>}/>
                    <Route path="/certifikaty" element={<CertificatesView/>}/>
                    <Route path="/kontakt" element={<ContactView/>}/>
                    <Route path="/hodnoceni/:code" element={<RateView/>}/>
                    <Route path="/hodnoceni_create/" element={<CreateRatingView/>}/>
                    {Object.keys(DOCUMENTS).map(key => {
                        return <Route path={key} key={key} element={<DocumentView documentName={key} key={key}/>}/>
                    })}
                    <Route path="*" element={<NotFoundView/>}/>
                </Routes>
            </ScrollWrapper>
        </BrowserRouter>
    </div>
}
