import "./ReferenceEntry.css"
import {IconStarFilled} from "@tabler/icons-react";

export default function ReferenceEntry(props: {
    name: string
    stars: number
    text: string
    date: number
}) {
    const {name, stars, text, date} = props;

    const dateObj = new Date(date);

    return <div className={"reference-entry"}>
        <p className={"name"}>{name}</p>
        <div className={"stars"}>
            {[0, 1, 2, 3, 4].map(v => {
                return <IconStarFilled size={28} className={v <= stars ? "active" : undefined} key={v}/>
            })}
        </div>
        <p className={"text"}>{text}</p>
        <p className={"date"}>{dateObj.getDate()}.{dateObj.getMonth() + 1}.{dateObj.getFullYear()}</p>
    </div>
}