import "./RateView.css"
import {useParams} from "react-router-dom";
import {IconStarFilled} from "@tabler/icons-react";
import {createRef, useEffect, useState} from "react";
import fetchWithRetry from "../../util/request";
import Button from "../../components/button/Button";

export default function RateView() {
    let {code} = useParams();

    const [stars, setStars] = useState(5);
    const [name, setName] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    const textAreaRef = createRef<HTMLTextAreaElement>();

    useEffect(() => {
        return fetchWithRetry(async res => {
            if (res.status === 400 || res.status === 404) {
                setErrorMessage("Tento odkaz hodnocení neexistuje")
                return true;
            }
            if (!res.ok) return false;
            let body = (await res.json()) as {
                name: string
            }
            setName(body.name);
            return true;
        }, "/api/rating", {
            method: "OPTIONS",
            headers: {
                "Content-Type": "text/plain"
            },
            body: code
        });
    }, [code])

    if (!!errorMessage) {
        return <div className={"rate-view"}>
            <p className={"error-message"}>{errorMessage}</p>
        </div>
    }

    if (!name) {
        return <div className={"rate-view"}>
            <div className={"big loader center margin"}/>
        </div>
    }

    return <div className={"rate-view"}>
        <h2>Hodnocení</h2>
        <div className={"stars"}>
            {[0, 1, 2, 3, 4].map(v => {
                return <IconStarFilled key={v} size={36} className={v <= stars ? "active" : undefined} onClick={() => {
                    setStars(v);
                }}/>
            })}
        </div>
        <textarea ref={textAreaRef} rows={5} placeholder={"Zde napište text hodnocení"}/>
        {message ? <p>{message}</p> : <p>Hodnotíte jako {name}</p>}
        <Button onClick={() => {
            const currentRef = textAreaRef.current;
            if (!currentRef) return;
            const value = currentRef.value;
            if (value.length < 20) {
                return setMessage("Text hodnocení by neměl být kratší než 20 znaků")
            }
            fetch("/api/rating", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    stars: stars,
                    content: value,
                    code: code
                })
            }).then(r => {
                r.json().then(b => {
                    setMessage(b.message);
                })
            })
        }}>Hodnotit</Button>
    </div>
}