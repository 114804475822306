export type CancelFunction = () => void;

export default function fetchWithRetry(
    callback: (res: Response) => boolean | Promise<Boolean>,
    input: RequestInfo | URL,
    init?: RequestInit
): CancelFunction {
    let timeout: NodeJS.Timeout | undefined;
    let cancelled = false;

    function cancel() {
        cancelled = true;
        if (timeout) clearTimeout(timeout);
    }

    function makeRequest() {
        if (cancelled) return;
        try {
            fetch(input, init).then(callback).then(v => {
                if (!v) timeout = setTimeout(makeRequest, 5000);
                else cancel();
            }).catch(() => setTimeout(makeRequest, 5000))
        } catch (e) {
            console.error(e);
        }
    }

    makeRequest();

    return cancel;
}