import "./LifecoachSection.css"
import photo from "../../../asset/photos/lifecoach.webp";

const LifeCoach = require("../../../asset/icon/1/ico.svg").ReactComponent;
const LifeCoachBg = require("../../../asset/icon/1/bg.svg").ReactComponent;

export default function LifecoachSection() {
    return <div className={"section type lifecoach"}>
        <div className={"text"}>
            <div className={"content"}>
                <LifeCoachBg className={"title-icon"} width={"80"}/>
                <h2>Životní Kouč</h2>
                <p>Při koučování vedu klienta k jeho splněným snům. </p>
            </div>
            {/*<div className={"button-container"}>*/}
            {/*    <Button href={"/kouc"}>Objednat</Button>*/}
            {/*</div>*/}
        </div>
        <div className={"photo-container"}>
            <img className={"photo"} src={photo} alt={""}/>
            <LifeCoach className={"background-icon"}/>
        </div>
    </div>
}