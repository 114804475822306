import "./WelcomeView.css"
import Header from "../../components/header/Header";
import InfoSection from "./info/InfoSection";
import SummarySection from "./summary/SummarySection";
import LifecoachSection from "./lifecoach/LifecoachSection";
import RelaxSection from "./relax/RelaxSection";
import ConsultationsSection from "./consultations/ConsultationsSection";
import WomenCircleSection from "./womencircle/WomenCircleSection";
import ReferencesSection from "./references/ReferencesSection";


export default function WelcomeView() {
    return <>
        <div className={"welcome-view"}>
            <Header/>
            <div className={"content"}>
                <InfoSection/>
                <SummarySection/>
                <LifecoachSection/>
                <ConsultationsSection/>
                <RelaxSection/>
                <WomenCircleSection/>
            </div>
        </div>
        <ReferencesSection/>
    </>
}