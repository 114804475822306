import "./InfoSection.css"

import light from "../../../asset/heart/light.webp";
import dark from "../../../asset/heart/dark.webp";

export default function InfoSection() {
    return <div className={"section info"}>
        <img className={"icon only-light"} src={light} alt={""}/>
        <img className={"icon only-dark"} src={dark} alt={""}/>
        <h2>Životní Kouč</h2>
        <h3>Život s úsměvem</h3>
    </div>
}