import "./CerificatesView.css"
import {Link} from "react-router-dom";
import Header from "../../components/header/Header";

const CERTIFICATES_FINANCE = [
    "OSVEDCENI-DOPLNKOVE_PENZIJNI_SPORENI",
    "OSVEDCENI-INVESTICNI_PORADCE_SE_SPECIALIZACI_PRO_KOLEKTIVNI_INVESTOVANI_IP-KI",
    "OSVEDCENI-DISTRIBUCE_POJISTENI_A_ZAJISTENI",
    "CERTIFIKAT-OSVEDCENI_O_VYKONANE_ODBORNE_ZKOUKY_PRO_SPOTREBITELSKE_UVERY",
]

const CERTIFICATES_COACH = [
    "CERTIFIKAT-LIFESTYLE_COACH",
    "OSVEDCENI-REKVALIFIKACE-KOUC",
    "CERTIFIKAT-SKOLA_PSYCHOSOMATIKY_A_ALTERNATIVNICH_TERAPII",
    "CERTIFIKAT-DVOULETY_VYCVIK_V_PROFESNICH_DOVEDNOSTECH",
    ]

export default function CertificatesView() {
    return <div className={"certificates-view"}>
        <Header/>
        <h2>Certifikáty</h2>
        <h3>Finance</h3>
        <div className={"certificates"}>
            {CERTIFICATES_FINANCE.map(name => {
                return <Link to={`/certifikaty/${name}.pdf`} target={"_blank"} key={name}>
                    <img className={"preview"} src={require(`../../asset/certificates/${name}.webp`)} alt={""}/>
                </Link>
            })}
        </div>
        <h3>Životní kouč</h3>
        <div className={"certificates"}>
            {CERTIFICATES_COACH.map(name => {
                return <Link to={`/certifikaty/${name}.pdf`} target={"_blank"} key={name}>
                    <img className={"preview"} src={require(`../../asset/certificates/${name}.webp`)} alt={""}/>
                </Link>
            })}
        </div>
    </div>
}