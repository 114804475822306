import "./ContactView.css"
import Header from "../../components/header/Header";
import {Link} from "react-router-dom";
import {IconMail, IconPhone} from "@tabler/icons-react";

const Logo = require("../../asset/logo/logo.svg").ReactComponent;

export default function ContactView() {
    return <div className={"contact-view"}>
        <Header/>
        <h2>Kontakt</h2>
        <div className={"contact-list"}>
            <div className={"name"}>
                <p className={"logo"}><Logo/> jushi</p>
                <p>Jaroslava Balcarová</p>
                <p className={"tagline"}>Životní kouč</p>
            </div>
            <div className={"contacts"}>
                <Link to={"callto:+420608771515"}><IconPhone/>608 771 515</Link>
                <Link to={"mailto:jushi@jushi.cz"}><IconMail/>jushi@jushi.cz</Link>
            </div>
        </div>
    </div>
}