import "./RelaxSection.css"
import photo from "../../../asset/photos/relax.webp";

const RelaxBg = require("../../../asset/icon/3/bg.svg").ReactComponent;

export default function RelaxSection() {
    return <div className={"section type relax"}>
        <div className={"text"}>
            <div className={"content"}>
                <RelaxBg className={"title-icon"} width={"80"}/>
                <h2>Relax</h2>
                <p>Pracuji s relaxačními technikami, které vás uvolní a zrelaxují.</p>
            </div>
            {/*<div className={"button-container"}>*/}
            {/*    <Button href={"/relax"}>Zvolit termín</Button>*/}
            {/*</div>*/}
        </div>
        <div className={"photo-container"}>
            <img className={"photo"} src={photo} alt={""}/>
            <RelaxBg className={"background-icon"}/>
        </div>
    </div>
}