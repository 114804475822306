import "./Document.css"
import MarkdownIt from "markdown-it";
import {markdownItFancyListPlugin} from "markdown-it-fancy-lists";

const parser = new MarkdownIt("default");
// @ts-ignore
parser.use(markdownItFancyListPlugin);

export default function Document(props: {
    children: string
}) {
    const { children } = props;

    return <div className={"document"}>
        <span dangerouslySetInnerHTML={{__html: parser.render(children)}}/>
    </div>
}